import {EmployeeWeeklyAverageWorkload, User, UserProfile, UserUrl} from "@co-common-libs/resources";
import {formatDateTime, simpleComparator} from "@co-common-libs/utils";
import {ColumnSpecifications, DateField, GenericTable, RowData} from "@co-frontend-libs/components";
import {
  actions,
  AppState,
  getTableSortingState,
  getToken,
  getUserArray,
  getUserUserProfileLookup,
} from "@co-frontend-libs/redux";
import {jsonFetch} from "@co-frontend-libs/utils";
import {Card, CardContent, CircularProgress, Grid} from "@material-ui/core";
import {globalConfig} from "frontend-global-config";
import React from "react";
import {defineMessages, useIntl} from "react-intl";
import {connect, useDispatch, useSelector} from "react-redux";
import {createStructuredSelector} from "reselect";

const TABLE_SORTING_IDENTIFIER = "WorkloadTable";

type WorkloadTableFieldID = "date" | "name" | "weeks16" | "weeks52";
type WorkloadTableColumnID = WorkloadTableFieldID;

interface WorkloadTableDataType extends RowData<WorkloadTableFieldID, UserUrl> {
  date: string;
  key: UserUrl;
  name: string;
  weeks16: string;
  weeks16Value: number;
  weeks52: string;
  weeks52Value: number;
}

interface WorkloadTableStateProps {
  token: string | null;
  userArray: readonly User[];
  userUserProfileLookup: (url: UserUrl) => UserProfile | undefined;
}

interface WorkloadTableOwnProps {
  active?: boolean;
  filterString: string;
  onClick: (userURL: UserUrl) => void;
}

interface WorkloadTableDispatchProps {
  putTableSortingState: (
    identifier: string,
    sortKey: string,
    sortDirection: "ASC" | "DESC",
  ) => void;
}

type WorkloadTableProps = WorkloadTableOwnProps & WorkloadTableStateProps;

const messages = defineMessages({
  date: {
    defaultMessage: "Dato",
    id: "workload-table.header.date",
  },
  description: {
    defaultMessage: "Se gennemsnitlig arbejdstid 16 eller 52 uger tilbage fra valgt dato.",
    id: "workload-table.description",
  },
  from: {
    defaultMessage: "Fra",
    id: "workload-table.from-date",
  },
  name: {
    defaultMessage: "Navn",
    id: "workload-table.header.name",
  },
  periodSearch: {
    defaultMessage: "Dato",
    id: "workload-table.period-search",
  },
  weeks16: {
    defaultMessage: "16 uger",
    id: "workload-table.header.16-weeks",
  },
  weeks52: {
    defaultMessage: "52 uger",
    id: "workload-table.header.52-weeks",
  },
});

const getEndOfDay = (date: Date): Date => {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59, 999);
};

const getDayBeforeCurrentDate = (): string => {
  const today = new Date();
  const yesterday = new Date(today.setDate(today.getDate() - 1));
  return getEndOfDay(yesterday).toISOString();
};

const buildColumnSpecifications = (
  formatMessage: any,
  onClick: (userURL: UserUrl) => void,
): ColumnSpecifications<
  WorkloadTableFieldID,
  WorkloadTableColumnID,
  UserUrl,
  WorkloadTableDataType
> => ({
  date: {
    field: "date",
    label: formatMessage(messages.date),
    onClick,
  },
  name: {
    field: "name",
    label: formatMessage(messages.name),
    onClick,
  },
  weeks16: {
    comparator: (a, b) => a.weeks16Value - b.weeks16Value,
    field: "weeks16",
    label: formatMessage(messages.weeks16),
    onClick,
  },
  weeks52: {
    comparator: (a, b) => a.weeks52Value - b.weeks52Value,
    field: "weeks52",
    label: formatMessage(messages.weeks52),
    onClick,
  },
});

const WorkloadTable: React.FC<WorkloadTableProps> = ({
  active,
  filterString,
  onClick,
  userArray,
  userUserProfileLookup,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [selectedDate, setSelectedDate] = React.useState<string>(getDayBeforeCurrentDate());
  const [tableData, setTableData] = React.useState<readonly WorkloadTableDataType[]>([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);

  const sortingStateSelector = React.useMemo(
    () => getTableSortingState(TABLE_SORTING_IDENTIFIER, "weeks16", "DESC"),
    [],
  );
  const {sortDirection, sortKey} = useSelector(sortingStateSelector);

  const columnSpecifications = React.useMemo(
    () => buildColumnSpecifications(intl.formatMessage, onClick),
    [intl.formatMessage, onClick],
  );

  const visibleColumns: WorkloadTableColumnID[] = React.useMemo(
    () => ["name", "weeks16", "weeks52", "date"],
    [],
  );

  const handleHeaderClick = React.useCallback(
    (key: WorkloadTableFieldID) => {
      const direction: "ASC" | "DESC" = sortKey === key && sortDirection === "ASC" ? "DESC" : "ASC";
      dispatch(actions.putTableSortingState(TABLE_SORTING_IDENTIFIER, key, direction));
    },
    [dispatch, sortKey, sortDirection],
  );

  const handleDateChange = React.useCallback((date: string | null) => {
    if (!date) {
      setSelectedDate(getDayBeforeCurrentDate());
      return;
    }
    const endOfDay = getEndOfDay(new Date(date));
    setSelectedDate(endOfDay.toISOString());
  }, []);

  const fetchWorkloadData = React.useCallback(async () => {
    setIsLoading(true);
    setError(null);

    try {
      const url = new URL(globalConfig.baseURL);
      url.pathname = "api/report/employeeWorkload/results/";
      if (selectedDate) {
        // Extract only the date portion for the API
        const dateOnly = selectedDate.split("T")[0];
        url.searchParams.append("date", dateOnly);
      }

      const response = await jsonFetch(url.toString(), "GET");

      if (!response || !response.data) {
        throw new Error("Invalid response from server");
      }

      const workloadMap = new Map(
        (response.data as EmployeeWeeklyAverageWorkload[]).map((item) => [item.user, item]),
      );

      const newData: WorkloadTableDataType[] = userArray
        .filter((user) => user.active)
        .map((user) => {
          const profile = userUserProfileLookup(user.url);
          if (!profile) return null;

          const workload = workloadMap.get(user.url);
          const weeks16Value = workload ? workload.averageHoursOver16Weeks : 0;
          const weeks52Value = workload ? workload.averageHoursOver52Weeks : 0;

          return {
            date: selectedDate
              ? formatDateTime(new Date(selectedDate).toISOString())
              : formatDateTime(new Date().toISOString()),
            key: user.url,
            name: profile.name || "",
            weeks16: weeks16Value.toFixed(2).replace(".", ","),
            weeks16Value,
            weeks52: weeks52Value.toFixed(2).replace(".", ","),
            weeks52Value,
          };
        })
        .filter(
          (employee): employee is WorkloadTableDataType =>
            employee !== null &&
            employee.name.trim() !== "" &&
            (employee.weeks16Value > 0 || employee.weeks52Value > 0),
        )
        .sort((a, b) => simpleComparator(a.name, b.name) || simpleComparator(a.key, b.key));

      setTableData(newData);
    } catch (err: unknown) {
      if (err instanceof Error) {
        setError("Error fetching data");
        setTableData([]);
      }
    } finally {
      setIsLoading(false);
    }
  }, [selectedDate, userArray, userUserProfileLookup]);

  React.useEffect(() => {
    fetchWorkloadData();
  }, [fetchWorkloadData]);

  if (!active) return null;

  return (
    <div>
      <Card>
        <CardContent>
          <div>{intl.formatMessage(messages.periodSearch)}</div>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <DateField
                autoOk
                fullWidth
                label={intl.formatMessage(messages.from)}
                margin="dense"
                maxDate={getDayBeforeCurrentDate()}
                onChange={handleDateChange}
                value={selectedDate}
                yearSuggestions="DATE_BEFORE"
              />
            </Grid>
            <Grid item xs={12}>
              <div style={{color: "rgba(0, 0, 0, 0.6)", marginTop: "8px"}}>
                {intl.formatMessage(messages.description)}
              </div>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      {error && <div style={{color: "red", margin: "1em 0"}}>{error}</div>}
      <div style={{marginTop: "1em"}}>
        {isLoading ? (
          <div style={{display: "flex", justifyContent: "center", padding: "2em"}}>
            <CircularProgress />
          </div>
        ) : (
          <GenericTable
            columns={columnSpecifications}
            entries={tableData}
            filterString={filterString}
            onHeaderClick={handleHeaderClick}
            sortBy={sortKey as WorkloadTableFieldID}
            sortDirection={sortDirection}
            visibleColumns={visibleColumns}
          />
        )}
      </div>
    </div>
  );
};

const ConnectedWorkloadTable = connect<
  WorkloadTableStateProps,
  WorkloadTableDispatchProps,
  WorkloadTableOwnProps,
  AppState
>(
  createStructuredSelector<AppState, WorkloadTableStateProps>({
    token: getToken,
    userArray: getUserArray,
    userUserProfileLookup: getUserUserProfileLookup,
  }),
)(WorkloadTable);

export {ConnectedWorkloadTable as WorkloadTable};
