import {Config, SettingMetaData} from "@co-common-libs/config";
import {useTheme} from "@material-ui/core";
import React, {useMemo} from "react";
import {FormattedMessage} from "react-intl";
import {
  checkConflictsWith,
  checkNoopWithoutAllOf,
  checkNoopWithoutOneOf,
  checkRequiresAllOf,
  checkRequiresOneOf,
  getValidator,
} from "./utils";

const JSON_INDENTATION_LEVEL = 2;

interface ErrorsBlockProps {
  nonBlankSettings: Partial<Config>;
  settingMetaData: SettingMetaData;
  value: unknown;
}

export function ErrorsBlock(props: ErrorsBlockProps): React.JSX.Element {
  const {nonBlankSettings, settingMetaData, value} = props;

  const {schema} = settingMetaData;
  const theme = useTheme();

  const validator = useMemo(() => getValidator(schema), [schema]);
  const schemaErrors = useMemo(() => validator(value), [value, validator]);

  let schemaErrorBlock: React.JSX.Element | undefined;
  if (schemaErrors) {
    schemaErrorBlock = (
      <>
        <FormattedMessage
          defaultMessage="Schema-issues"
          id="system-setup.header.schema-issues"
          tagName="h4"
        />
        <pre>
          <code>{JSON.stringify(schemaErrors, null, JSON_INDENTATION_LEVEL)}</code>
        </pre>
      </>
    );
  }

  let schemaBlock: React.JSX.Element | undefined;
  if (schemaErrors) {
    schemaBlock = (
      <>
        <FormattedMessage defaultMessage="Schema:" id="system-setup.header.schema" tagName="h4" />
        <pre>
          <code>{JSON.stringify(schema, null, JSON_INDENTATION_LEVEL)}</code>
        </pre>
      </>
    );
  }

  let dependencyErrorBlock: React.JSX.Element | undefined;
  if (value) {
    const dependencyErrors: React.JSX.Element[] = [];
    const conflictsWithIssue = checkConflictsWith(settingMetaData, nonBlankSettings, value);
    if (conflictsWithIssue) {
      dependencyErrors.push(
        <div key="conflicts-with" style={{color: theme.palette.error.main}}>
          <FormattedMessage
            defaultMessage="Må ikke bruges sammen med: {others}"
            id="system-setup.text.conflicts-with"
            tagName="div"
            values={{others: conflictsWithIssue.join(", ")}}
          />
        </div>,
      );
    }
    const requiresAllOfIssue = checkRequiresAllOf(settingMetaData, nonBlankSettings, value);
    if (requiresAllOfIssue) {
      dependencyErrors.push(
        <div key="requires-all-of" style={{color: theme.palette.error.main}}>
          <FormattedMessage
            defaultMessage="Kræver også: {others}"
            id="system-setup.text.requires-all-of"
            tagName="div"
            values={{others: requiresAllOfIssue.join(", ")}}
          />
        </div>,
      );
    }
    const requiresOneOfIssue = checkRequiresOneOf(settingMetaData, nonBlankSettings, value);
    if (requiresOneOfIssue) {
      dependencyErrors.push(
        <div key="requires-one-of" style={{color: theme.palette.error.main}}>
          <FormattedMessage
            defaultMessage="Kræver mindst en af: {others}"
            id="system-setup.text.requires-one-of"
            tagName="div"
            values={{others: requiresOneOfIssue.join(", ")}}
          />
        </div>,
      );
    }
    const noopWithoutAllOfIssue = checkNoopWithoutAllOf(settingMetaData, nonBlankSettings, value);
    if (noopWithoutAllOfIssue) {
      dependencyErrors.push(
        <div key="noop-without-all-of" style={{color: theme.palette.warning.main}}>
          <FormattedMessage
            defaultMessage="Har ingen effekt uden: {others}"
            id="system-setup.text.noop-without-all-of"
            tagName="div"
            values={{others: noopWithoutAllOfIssue.join(", ")}}
          />
        </div>,
      );
    }
    const noopWithoutOneOfIssue = checkNoopWithoutOneOf(settingMetaData, nonBlankSettings, value);
    if (noopWithoutOneOfIssue) {
      dependencyErrors.push(
        <div key="noop-without-one-of" style={{color: theme.palette.warning.main}}>
          <FormattedMessage
            defaultMessage="Har ingen effekt uden mindst en af: {others}"
            id="system-setup.text.noop-without-one-of"
            tagName="div"
            values={{others: noopWithoutOneOfIssue.join(", ")}}
          />
        </div>,
      );
    }
    if (dependencyErrors.length) {
      dependencyErrorBlock = (
        <>
          <FormattedMessage
            defaultMessage="Afhængigheder"
            id="system-setup.header.dependencies"
            tagName="h4"
          />
          {dependencyErrors}
        </>
      );
    }
  }

  if (schemaErrorBlock || dependencyErrorBlock) {
    return (
      <>
        <div style={{color: theme.palette.error.main}}>
          <FormattedMessage defaultMessage="Fejl:" id="system-setup.header.errors" tagName="h3" />
          {schemaErrorBlock}
          {dependencyErrorBlock}
        </div>
        {schemaBlock}
      </>
    );
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>;
}
