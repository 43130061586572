import {CustomerUrl} from "@co-common-libs/resources";
import {defaultToNull} from "@co-common-libs/utils";
import {ResponsiveDialog} from "@co-frontend-libs/components";
import {actions, getCustomerSettings, getDefaultLocationType} from "@co-frontend-libs/redux";
import {DialogContent} from "@material-ui/core";
import {
  createCustomer,
  createLocation,
  simpleObjectReducer,
  useResetObjectOnCondition,
} from "app-utils";
import React, {useCallback, useReducer} from "react";
import {useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {CustomerFormWithCvrAutocomplete} from "../customer-form/customer-form-with-cvr-autocomplete";
import {hasMissingOrInvalidInputs} from "../customer-form/has-missing-or-invalid-inputs";
import {transformToCustomerFormPart} from "../customer-form/transform-to-customer-form-part";
import {CustomerFormPart} from "../customer-form/types";
import {useCustomerFormErrorsAndWarnings} from "../customer-form/use-customer-form-errors-and-warnings";
import {useCustomerFormInputDefinitions} from "../customer-form/use-customer-form-input-definitions";

interface CustomerCreateDialogProps {
  customer?: Partial<CustomerFormPart>;
  onCancel: () => void;
  onCreateSuccess: (customerURL: CustomerUrl) => void;
  open: boolean;
}

export const CustomerCreateDialog = React.memo(function CustomerCreateDialog(
  props: CustomerCreateDialogProps,
): React.JSX.Element {
  const {customer, onCancel, onCreateSuccess, open} = props;
  const customerSettings = useSelector(getCustomerSettings);

  const defaultLocationType = useSelector(getDefaultLocationType);

  const [formCustomer, formDispatch] = useReducer(
    simpleObjectReducer<CustomerFormPart>,
    customer,
    transformToCustomerFormPart,
  );
  useResetObjectOnCondition(customer, formDispatch, open, transformToCustomerFormPart);
  const inputDefinitions = useCustomerFormInputDefinitions(null, true);
  const errorsAndWarnings = useCustomerFormErrorsAndWarnings(formCustomer, null, inputDefinitions);

  const dispatch = useDispatch();
  const intl = useIntl();

  const handleOk = useCallback(() => {
    const newCustomer = createCustomer(formCustomer);
    if (customerSettings.enableAutomaticNewCustomerDefaultLocationCreation) {
      const newCustomerLocation = createLocation({
        address: newCustomer.address,
        city: newCustomer.city,
        customer: newCustomer.url,
        favorite: true,
        locationType: defaultToNull(defaultLocationType?.url),
        postalCode: newCustomer.postalCode,
      });
      dispatch(actions.create(newCustomer));
      dispatch(actions.create(newCustomerLocation));
      onCreateSuccess(newCustomer.url);
    }
  }, [
    customerSettings.enableAutomaticNewCustomerDefaultLocationCreation,
    defaultLocationType?.url,
    dispatch,
    formCustomer,
    onCreateSuccess,
  ]);

  return (
    <ResponsiveDialog
      okDisabled={hasMissingOrInvalidInputs(formCustomer, inputDefinitions, errorsAndWarnings)}
      onCancel={onCancel}
      onOk={handleOk}
      open={open}
      title={intl.formatMessage({defaultMessage: "Opret kunde"})}
    >
      <DialogContent>
        <CustomerFormWithCvrAutocomplete
          customer={customer}
          formCustomer={formCustomer}
          formDispatch={formDispatch}
          {...inputDefinitions}
          {...errorsAndWarnings}
        />
      </DialogContent>
    </ResponsiveDialog>
  );
});
