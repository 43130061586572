import {LocationTypeUrl, ProductUrl} from "../resource-urls";
import {ResourceInstance} from "./base";

export const locationTypeIcons = [
  "account-hard-hat",
  "agenda",
  "barley",
  "barn",
  "content-copy",
  "database",
  "emoticon-poop",
  "excavator",
  "factory",
  "flag-variant",
  "gas-station",
  "hammer-wrench",
  "hockey-puck",
  "map-marker-circle",
  "pig",
  "pine-tree",
  "semantic",
  "silo",
  "snowflake",
  "sprout",
  "terrain",
  "texture-box",
  "tractor",
  "truck",
  "warehouse",
  "currency-usd",
  "car-hatchback",
  "car-estate",
  "van-utility",
  "water-pump",
  "wheel-barrow",
  "sprinkler-variant",
  "bulldozer",
  "summit",
] as const;

export type LocationTypeIcon = (typeof locationTypeIcons)[number];

export interface LocationType extends ResourceInstance<LocationTypeUrl> {
  readonly color: string;
  readonly icon: LocationTypeIcon;
  readonly identifier: string;
  readonly name: string;
  readonly products: readonly ProductUrl[];
}

export type EmptyLocationType = {
  active: boolean;
  attention: string;
  cellphone: string;
  coordinatesFromAddress: boolean;
  email: string;
  fieldAreaHa: number | null;
  fieldBlock: string;
  fieldCrop: string;
  fieldDataChanged: Date | null;
  fieldFromUpload: boolean;
  fieldJournalNumber: string;
  fieldNumber: string;
  fieldRecordYear: number | null;
  fieldVatNumber: string;
  geojson: any | null;
  latitude: number | null;
  locationTypeId: string | null;
  logOnlyLocation: boolean;
  longitude: number | null;
  mergedToId: string | null;
  name: string;
  phone: string;
  remoteUrl: string;
  shapefileId: string | null;
  workplaceOnlyLocation: boolean;
};

export const emptyLocationType: EmptyLocationType = {
  active: true,
  attention: "",
  cellphone: "",
  coordinatesFromAddress: false,
  email: "",
  fieldAreaHa: null,
  fieldBlock: "",
  fieldCrop: "",
  fieldDataChanged: null,
  fieldFromUpload: false,
  fieldJournalNumber: "",
  fieldNumber: "",
  fieldRecordYear: null,
  fieldVatNumber: "",
  geojson: null,
  latitude: null,
  locationTypeId: null,
  logOnlyLocation: false,
  longitude: null,
  mergedToId: null,
  name: "",
  phone: "",
  remoteUrl: "",
  shapefileId: null,
  workplaceOnlyLocation: false,
};
